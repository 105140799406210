const linkResolver = ({ node, key, value }) => doc => {
  if (doc.uid !== 'index' && doc.type === 'page') {
    return `/${doc.uid}`
  }
  if (doc.type === 'case_study') {
    return `/case-studies/${doc.uid}`
  }
  return `/`
}

module.exports = linkResolver
